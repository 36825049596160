import { Button, Checkbox, Chip, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ItemCard } from './ItemCard';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedReducersType } from '../store/modalTypes';
import { CategoryWithInventory, Item, CategoriesTableProps, CategoryRowProps, DeviceMinSize, ClosedSalesStage, OpportunityStatusDependency } from '../types';
import { setReadOnly } from '../store/authActions';
import { openInventoryCompletedModal } from '../store/modalActions';
import swal from 'sweetalert';

import strings from '../config/strings';
const {
    layout: { inventory, infoMessage },
} = strings.UI;

const useStyles = makeStyles(() => {
    return {
        title: {},
        deleteItemsButton: {
            marginLeft: '1000',
            marginRight: '100px',
        },
        addItemsButton: {
            marginLeft: '900',
        },
        div: {
            float: 'right',
        },
    };
});

const emptyItem = {
    name: '',
    valuePerItem: undefined,
    quantity: 1,
    weightPounds: '0',
    description: '',
    cubicFeet: '0',
    category: '',
    isArt: false
};

const Row: React.FC<CategoryRowProps> = ({ row, setShowEditItem, setInitItem, setAddItem, setIsRowOpen }) => {
    const [open, setOpen] = useState(false);
    const { authReducer } = useSelector((state: CombinedReducersType) => state);
    const { readOnly } = authReducer;
    let totalCategoryValue = 0;
    let totalCategoryItems = 0;
    row.items.map((v: Item) => {
        totalCategoryValue += v.valuePerItem * v.quantity;
        totalCategoryItems += 1 * v.quantity;
    });

    useEffect(() => {
        const isDesktop = window.innerWidth > DeviceMinSize.desktop;
        setIsRowOpen(isDesktop);
        setOpen(isDesktop);
    }, []);

    const setOpenState = () => {
        setIsRowOpen(!open)
        setOpen(!open)
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', display: 'flex', justifyContent: 'space-between' } }}>
                <TableCell sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            disabled={row.items.length ? false : true}
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenState()}
                        >
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1" sx={{ lineHeight: '1', marginLeft: '6px' }}>
                                    {row.name} ({totalCategoryItems})
                                </Typography>
                                {row.items.length != 0 && (
                                    <Typography sx={{ lineHeight: '1', marginLeft: '6px', color: '#0000008a' }}>
                                        Total: ${totalCategoryValue}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <IconButton
                        disabled={readOnly || row.isDisabled}
                        size="small"
                        onClick={() => {
                            setAddItem(true);
                            const itemWithCategory = emptyItem;
                            itemWithCategory.category = row.id;
                            setInitItem(itemWithCategory);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ padding: '0px', backgroundColor: '#F6F7F9' }} colSpan={6}>
                    <Collapse in={open} timeout={0} unmountOnExit sx={{ margin: '16px' }}>
                        {row.items.map((item: Item, index: number) => (
                            <ItemCard
                                key={index}
                                index={index}
                                item={item}
                                setShowEditItem={setShowEditItem}
                                setInitItem={setInitItem}
                                itemListLength={row.items.length}
                            />
                        ))}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export const CategoriesTable: React.FC<CategoriesTableProps> = ({
    coverage,
    items,
    categories,
    setAddItem,
    setShowInfoModal,
    setInitItem,
    setShowEditItem,
    pastEffectiveDateError,
    setIsRowOpen
}) => {
    const classes = useStyles();
    const token = localStorage.getItem('private_token');
    const { authReducer } = useSelector((state: CombinedReducersType) => state);
    const dispatch = useDispatch();
    const { readOnly, providerSettings } = authReducer;
    let totalValue = 0;
    let totalItems = 0;
    
    const { inventoryReducer } = useSelector((state: CombinedReducersType) => state)
    const [checkboxInv, setCheckboxInv] = useState(inventoryReducer.inventoryStatus);

    const readOnlyDateIsPast: boolean | null =
    coverage && new Date().getTime() > new Date(coverage.readOnlyDate).getTime();
    const salesStageEnded: boolean | null = (coverage?.salesStage == ClosedSalesStage.purchased || coverage?.salesStage == ClosedSalesStage.canceled || coverage?.salesStage == ClosedSalesStage.duplicate) && providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased;
    
    items.map((v: Item) => {
        totalValue += v.valuePerItem * v.quantity;
        totalItems += 1 * v.quantity;
    });
    /* eslint-disable */
    React.useEffect(() =>{
        setCheckboxInv(inventoryReducer.inventoryStatus)
    },[inventoryReducer.inventoryStatus])

    React.useEffect(() =>{
        if (readOnlyDateIsPast || readOnly) {
            setCheckboxInv(true);
            dispatch(setReadOnly(true))
        } 
    },[])

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (true) {
            case readOnlyDateIsPast:
                setCheckboxInv(true);
                dispatch(setReadOnly(true));
                swal(inventory.checkbox.alertError.title, pastEffectiveDateError, "error");
                break;
            case salesStageEnded:
                setCheckboxInv(true);
                dispatch(setReadOnly(true));
                swal(inventory.checkbox.alertError.title, pastEffectiveDateError, "error");
                break;
            case (event.target.checked && coverage?.program?.types?.declaration.toLowerCase() === 'entire shipment'):
                dispatch(openInventoryCompletedModal())
                break;
            case (event.target.checked && coverage?.program?.types?.declaration.toLowerCase() === 'specific items'):
                totalItems > 0 
                    ? dispatch(openInventoryCompletedModal()) 
                    : swal(inventory.checkbox.alertError.title, inventory.checkbox.alertError.emptyInventory, "error");
                break;
            case (!event.target.checked):
                const alertText = inventory.checkbox.alertError.contact.replaceAll('{contact}', providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact).replaceAll('{coverageId}', (coverage?.friendlyId || ''));
                swal(inventory.checkbox.alertError.title, alertText, "error");
                break;
            default:
                break;
        }
    };    

    return (
        <Box sx = {{ marginTop: '5rem' }}>
            <Box display="flex" sx={{ marginBottom: '15px'}} justifyContent="space-between">
                <Box display="flex" flexDirection={{ xs: 'row', sm: 'row' }}
                    sx={{
                        '@media (max-width: 450px)': {
                            flexDirection: 'column',
                        }
                    }}
                >
                    <Typography color="#003D7B" variant="h6" marginRight={{ xs: '10px', sm: '10px' }}>
                        {inventory.title}
                    </Typography>
                    <Chip
                        label={totalItems + ' Items: $' + totalValue}
                        sx={{
                            backgroundColor: '#E2F0F8',
                        }}
                    />
                </Box> 
                <Box>
                    <Button
                        disabled={readOnly}
                        className={classes.deleteItemsButton}
                        color="primary"
                        variant="text"
                        onClick={() => setShowInfoModal(true)}
                        sx={{ marginRight: '10px', padding: { xs: '0', sm: '8px' }, minWidth: '30px' }}
                    >
                        <DeleteForeverIcon sx={{ width: '20px', marginRight: '8px', marginLeft: '-4px' }} />
                        <Typography display={{ xs: 'none', sm: 'block' }} variant="button">
                            {inventory.buttons.deleteAll}
                        </Typography>
                    </Button>
                    <Button
                        disabled={readOnly}
                        sx={{
                            marginRight: '10px',
                            padding: { xs: '0', sm: '8px' },
                            minWidth: '30px',
                        }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setAddItem(true);
                            setInitItem(null);
                        }}
                    >
                        <AddIcon
                            sx={{
                                width: '20px',
                                marginRight: { sm: '8px' },
                                marginLeft: { sm: '-4px' },
                            }}
                        />
                        <Typography display={{ xs: 'none', sm: 'block' }} variant="button">
                            {inventory.buttons.addItems}
                        </Typography>
                    </Button>
                </Box>
            </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flexStart', fontSize: '16px', color: '#808080' }}>
                    <Typography>
                        {infoMessage.declarationTypeDetail}
                    </Typography>
                </Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkboxInv}
                                                onChange={onCheckboxChange}
                                            />
                                        }
                                        label={inventory.checkbox.label}
                                    />
                                </FormGroup>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((row: CategoryWithInventory) => (
                            row.isDisabled && row.items.length === 0 ? null : <Row
                                key={row.name}
                                row={row}
                                setShowEditItem={setShowEditItem}
                                setInitItem={setInitItem}
                                setAddItem={setAddItem}
                                setIsRowOpen={setIsRowOpen}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
