import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { closeInventoryCompletedModal } from '../../store/modalActions';
import { setReadOnly } from '../../store/authActions';
import { setInventoryStatus } from '../../store/inventoryActions';
import { updateInventoryCompleted } from '../../services/authService';

import strings  from '../../config/strings';
import { useNavigate } from 'react-router-dom';

const { modals : { inventoryCompleted } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '8px',
            textAlign: 'center'
        },
        button: {
            margin: '40px',
        },
    };
});

interface Props {
    isOpen: boolean;
}

export const ConfirmInventory = ({ isOpen }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const token = localStorage.getItem('private_token');
    const handleCancel = () => dispatch(closeInventoryCompletedModal());;
    const handleConfirm = () => {
        updateInventoryCompleted(token, true);
        dispatch(setInventoryStatus(true));
        dispatch(setReadOnly(true));
        dispatch(closeInventoryCompletedModal());
    };

    return (
        <CustomModal isOpen={isOpen} onClose={handleCancel}>
            <form>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {inventoryCompleted.title}
            </Typography>
            <Typography
                lineHeight="1.5rem"
                align="center"
                fontWeight="normal"
                variant="caption"
                component="h2"
                sx={{ whiteSpace: 'pre-line', color: '#5A6A7F', fontSize: '20px' }}
            >
                {inventoryCompleted.description}
            </Typography>
            <div className={classes.disclamer}>
                <Typography align="left" fontSize=".9rem" variant="caption" color="#5A6A7F">
                    {inventoryCompleted.disclaimer}
                </Typography>
            </div>
            <Typography marginTop="40px" align="right">
                <Button
                    sx={{ marginRight: '16px' }}
                    className={classes.button}
                    color="primary"
                    variant="text"
                    onClick={handleCancel}
                >
                    {inventoryCompleted.buttons.cancel}
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handleConfirm}
                >
                    {inventoryCompleted.buttons.confirm}
                </Button>
            </Typography>
            </form>
        </CustomModal>
    );
};
