import React from 'react'

export const InsuranceTermsTemplate = () => {
  return (
    <div>
      <ol>
        <li>Packed By Owner (PBO) Items (items you pack yourself):
            <ul>
                <li>Claims covered when loss or damage is noted at delivery, photos of sealed box required.</li>
                <li>Maximum recovery $250 per box, and $2,500 per claim.</li>
                <li>Damage to plastic containers, or contents of these containers, is excluded.</li>
            </ul>
        </li>
        <li>Carrier Packed (CP) Items (items packed by the mover):
            <ul>
                <li>Damage must be reported within 45 days of delivery.</li>
            </ul>
        </li>
        <li>Furniture and Non-Packed Items:
            <ul>
                <li>Damage must be noted at delivery or reported within 7 days.</li>
                <li>Any item constructed of particle board or MDF is excluded.</li>
            </ul>
        </li>
        <li>Missing Items:
            <ul>
                <li>Coverage when mover completes full inventory and item/box is noted missing on delivery paperwork.</li>
            </ul>
        </li>
        <li>Mechanical/Electrical Items:
            <ul>
                <li>Coverage when there is external damage only.</li>
            </ul>
        </li>
        <li>Pairs & Sets:
            <ul>
                <li>Payment only for proportionate value of the damaged item, not the entire set.</li>
            </ul>
        </li>
        <li>Non-Insurable Items (any of the following are not covered):
            <ul>
                <li>Jewelry, watches, gemstones, cash, currency or bank notes, deeds, travelers check, coin or stamp collections, sports memorabilia, alcoholic beverages, foods, medications, negotiable items, furs or garments trimmed with fur, ammunition, contraband, cigarettes, laptop or tablet computers, any liquid cooled computer and its parts, mobile or cellular telephones, automobiles, non-household items.</li>
            </ul>
        </li>
        <li>Loss/Damages Not Covered:
            <ul>
                <li>Appraisal fees, inspection fees, shipping charges, transportation charges, sales tax, property damage, damage caused during assembly or disassembly of items that require a 3rd party company, manufacturers warranties, items having no commercial value, or items of sentimental value.</li>
                <li>Loss/Damage caused by wear and tear, deterioration, changes in climatic conditions, mold and mildew, infestations, pre-existing damages, inherent vice, wrinkled/soiled: clothing, linens, drapes, or rugs.</li>
                <li>Loss/Damage caused by flood, surface water, waves, whether driven by wind or not.</li>
            </ul>
        </li>
        <li>Items Requiring Specific Packing:
            <ul>
                <li>Delicate/fragile Items must be professionally packed for coverage.</li>
                <li>Delicate/fragile items valued at $5,000 or higher must be professionally crated.</li>
            </ul>
        </li>
        <li>Specific Items:
            <ul>
                <li>TVs, mattresses, firearms, lamps, bikes, pianos, motorcycles, and grandfather clocks must be professionally packed by the mover and have detailed packing requirements for coverage.</li>
            </ul>
        </li>
      </ol>
    </div>
  )
}
