import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CombinedReducersType } from '../store/modalTypes';

import strings  from '../config/strings';
import { ClosedSalesStage, Coverage, OpportunityStatusDependency, ProviderSetting } from '../types';
const { layout : { infoMessage } } = strings.UI;

interface Props {
    setEffectiveMessage: React.Dispatch<React.SetStateAction<boolean>>;
    readOnlyDate: string | null;
    coverageData: Coverage | null;
    providerSettings: ProviderSetting | null;
    setPastEffectiveDateError: React.Dispatch<React.SetStateAction<string>>
}

const useStyles = makeStyles(() => {   
    return {
        container: {
            fontSize: '0.85rem',
            display: 'flex',
            position: 'absolute',
            marginRight: '1.5rem'
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            gap:'12px',
            border: '#2A87C9 1px solid',
            borderRadius: '6px',
            backgroundSize: 'cover',
            width: 'auto',
            overflow: 'auto',
            padding: '9px 16px',
            justifyContent: 'space-around'
        },
        icon: {
            color: '#2A87C9'
        },
        text: {
            color: '#2A87C9',
        }

    };
});

const getDate = function(date : Date){
    return ("0" + date.getUTCDate()).slice(-2);
}

const getMonth = function(date : Date){
    return ("0" + (date.getUTCMonth() + 1)).slice(-2);
}

const substractOneDay = (dateString: string) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date;
}

export const EffectiveMessage = ({ setEffectiveMessage, readOnlyDate, coverageData, providerSettings, setPastEffectiveDateError } : Props) => {
    const { inventoryReducer, authReducer } = useSelector((state: CombinedReducersType) => state);
    const isNotReadOnly = !authReducer.readOnly;
    const isInventoryCompleted = inventoryReducer.inventoryStatus;
    const classes = useStyles();
    let text : string;
    if (isNotReadOnly){
        if (!readOnlyDate){
            return (<div style={{display: 'none'}}></div>);
        }
        const date = new Date(substractOneDay(readOnlyDate));
        text = infoMessage.effectiveDate.replaceAll("{date}",`${getMonth(date)}/${getDate(date)}/${date.getUTCFullYear()}`);
    } else {
        text = `${infoMessage.readOnly} ${providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact}`.replaceAll('{coverageId}', (coverageData?.friendlyId || ''));
        if(coverageData){
            if(providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased && coverageData.salesStage == ClosedSalesStage.purchased){
                text = `${infoMessage.readOnlyStatus.beforePurchased.purchased} ${providerSettings.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact}`.replaceAll('{coverageId}', (coverageData?.friendlyId || ''));
            }
            if(providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased && coverageData.salesStage == ClosedSalesStage.canceled){
                text = `${infoMessage.readOnlyStatus.beforePurchased.canceled} ${providerSettings.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact}`.replaceAll('{coverageId}', (coverageData?.friendlyId || ''));
            }
            if(providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased && coverageData.salesStage == ClosedSalesStage.duplicate){
                text = `${infoMessage.readOnlyStatus.beforePurchased.duplicate} ${providerSettings.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact}`.replaceAll('{coverageId}', (coverageData?.friendlyId || ''));
            }
            if(providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.afterPurchased && !Object.values(ClosedSalesStage).includes(coverageData.salesStage as ClosedSalesStage)){
                text = `${infoMessage.readOnlyStatus.afterPurchased.default} ${infoMessage.customerServiceContact.replaceAll('{coverageId}', (coverageData?.friendlyId || ''))}`;
            }
            const today = new Date();
            const effective = new Date(coverageData.effectiveDate);

            if(today > effective){
                text = `${infoMessage.readOnlyAfterMove} ${infoMessage.customerServiceContact.replaceAll('{coverageId}', (coverageData?.friendlyId || ''))}`;
            }
        }
        if(isInventoryCompleted){
            text = `${infoMessage.inventoryCompleted} ${providerSettings?.opportunityStatusDependency == OpportunityStatusDependency.beforePurchased ? infoMessage.contact : infoMessage.customerServiceContact}`.replaceAll('{coverageId}', (coverageData?.friendlyId || ''))
        }
    }
    useEffect(() => {
        if (coverageData) {
            const today = new Date();
            const effective = new Date(coverageData.effectiveDate);
    
            if (today > effective) {
                setPastEffectiveDateError(`${infoMessage.readOnlyAfterMove}`);
            } else {
                setPastEffectiveDateError(text);
            }
        }
    }, [coverageData, text, setPastEffectiveDateError]);

    const handleClose = () => {
        setEffectiveMessage(false);
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.wrapper}>
                <InfoIcon className={classes.icon} sx={{fontSize: '1.3rem'}}/>
                <Typography className={classes.text} sx={{fontSize: 'inherit'}}>
                    {text}
                </Typography>
                <CloseIcon className={classes.icon} sx={{cursor: 'pointer'}} fontSize='small' onClick={handleClose}/>
            </Box>
        </Box>
    );
};
