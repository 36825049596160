export default {
    UI: {
        layout: {
            leftPane: {
                declarationType: "Declaration Type",
                changePin: "Change Pin",
                addPin: "Add Pin",
                bottomCopy: "Relocation Insurance Group, LLC.",
                name: "Name:",
                coverageLimit: "Coverage Limit:",
                helpMessagge: "For help, call +1 (908) 731-6944 or email help@relocationinsurance.com and reference ID# {coverageId}. Representatives are available Monday through Friday, 9:00 am to 6:00 pm EST.",
                helpMessageCustomerService: "For help, call +1 (973) 510-2523 or email customercare@relocationinsurance.com and reference ID# {coverageId}. Representatives are available Monday through Friday, 9:00 am to 6:00 pm EST.",
                currency: "USD $ {value}"
            }, 
            infoMessage: {
                contact: "contact us at +1 (908) 731-6944 or email help@relocationinsurance.com and reference ID# {coverageId}.",
                customerServiceContact: "contact us at +1 (973) 510-2523 or email customercare@relocationinsurance.com and reference ID# {coverageId}.",
                inventoryCompleted: "You marked this inventory list as complete. If you need to unlock your list and make further changes, please",
                readOnlyStatus:{
                    beforePurchased: {
                        purchased: "Inventory cannot be modified because this coverage is already purchased. To make modifications to your coverage or inventory list, please",
                        canceled: "Inventory cannot be modified because this coverage has been canceled. To make modifications to your coverage or inventory list, please",
                        duplicate: "Inventory cannot be modified because this coverage is marked as a duplicate. To make modifications to your coverage or inventory list, please",
                    },
                    afterPurchased: {
                        default: "Inventory modification is only allowed after purchase for your current provider. Please"
                    }
                },
                readOnly: "Inventory cannot be modified because it is too close to your move date. Please",
                readOnlyAfterMove: "Inventory cannot be modified because the move is in progress or has already taken place. If the move date has changed, please",
                effectiveDate: "Inventory can be modified through {date}, after that date inventory modifications will not be accepted",
                declarationTypeDetail: "This insurance requires listing any item that would cost $2,000 or more to replace. Items not listed will only be covered for up to $2,000."
            },
            inventory: {
                title: "Inventory List",
                buttons: {
                    deleteAll: "DELETE ALL",
                    addItems: "ADD ITEMS"
                },
                itemView: {
                    replacementCost: "Replacement cost",
                    quantity: "Quantity",
                    total: "Total"
                },
                checkbox: {
                    label: "Mark inventory as completed",
                    alertError: {
                        title: "Inventory List is Locked",
                        salesStageEnded: "Coverage is already purchased/canceled",
                        effectiveDateIsPast: "Effective date is past",
                        emptyInventory: "Your inventory is empty",
                        cannotUncheck: "You can not uncheck a marked inventory",
                        contact: "This list can no longer be modified. If you need to unlock your list and make further changes, please {contact}"
                    },
                    alertUnmark: {
                        title: "Your inventory has been unmarked",
                        description: "You can edit your inventory again"
                    }
                }
            }
        },
        modals: {
            declarationType: {
                title: "Confirm your declaration type",
                entireShipment: "Entire shipment",
                specificItems: "Specific Items",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "CONTINUE"
                },
                info: {
                    entireShipmentText: 'This option offers coverage if you are interested in insuring your entire shipment for Local, Intrastate, Interstate, and International moves. The total coverage amount is determined when you, the assured, place a lump sum value on all of your goods to be insured considering the declared value of the shipment equals no less than $8.00 (*)times the weight of the shipment. Any items individually valued at $1,000.00 (USD) (*) or more need to be listed separately in order to be covered at their specific replacement value.',
                    specificItemsText: 'This option offers coverage for specific items for Local, Intrastate, Interstate and International moves. For your benefit and for proper coverage, you must list and declare a replacement value on all of the items that you wish to insure. Items may be categorized as a group, such as "living room set, 7 pieces". However, please remember that in the event a claim is filed for any of the items in that category, the settlement amount may reflect the average value of the declared grouped items in that category.',
                    goBackButton: "Go Back"
                }
            },
            changePin: {
                title: "Change your PIN",
                fields: {
                    current: "CURRENT PIN",
                    new: "NEW PIN"
                },
                legend: "Must contain 4 numbers",
                error: "Wrong PIN. Retry",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DONE"
                }
            },
            login: {
                title: "Enter your PIN",
                fields: {
                    pin: "PIN"
                },
                legend: "Must contain 4 numbers",
                buttons: {
                    resetPIN: "RESET PIN",
                    confirm: "DONE"
                },
                error: "Wrong PIN. Retry"
            },
            addPin: {
                title: "Create your PIN",
                fields: {
                    newPin: "NEW PIN"
                },
                legend: "Must contain 4 numbers",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DONE"
                }
            },
            resetPin: {
                title: "PIN reset requested",
                description: "Your new PIN will be sent to your e-mail"
            },
            welcome: {
                setPin: {
                    title: "Would you like to set a PIN?",
                    description: "You can create a simple 4 digit pin to restrict access to your inventory list. Setting a PIN is not required and is an additional layer of protection. This allows you to share the link to your inventory list with someone else, but keep it secure by sharing the PIN separately.",
                    buttons: {
                        cancel: "Not now",
                        confirm: "Yes"
                    }
                },
                greetings: {
                    title: "Thank you for choosing Moving Insurance!",
                    body: `Please identify any high value items being moved that would cost $2,000 or more to replace.`,
                    warning: `If you don’t have any items valued over $2,000 you’d like to insure, please check the box labeled “Mark inventory as completed”`,
                    disclaimer: "*Remember: Following items are excluded from the coverage service: Furs, Alcohol, etc",
                    buttons: {
                        confirm: "START"
                    }
                }
            },
            addEditItem: {
                addtitle: "Add new item",
                editTitle: "Edit item",
                warningAmount: "Items valued at $50,000 and over MAY be subject to certain restrictions or underwriting requirements. Please review the Terms and Conditions for these restrictions, or {contact} Representatives are available Monday through Friday, 9:00 am to 5:00 pm EST. Please note that if you can send a photo of the item and/or link to the item’s description, this will help expedite the process.",
                formLabels: {
                    name: "Item name",
                    quantity: "Quantity",
                    category: "Category",
                    replacementCost: "Replacement cost per item",
                    description: "Description/conditions",
                    isArt: "Art",
                    isArtDescription: `Please check the Art box if the item is considered artwork. This could be sculptures, paintings, antiques, statues, etc.`
                },
                buttons: {
                    cancel: "CANCEL",
                    confirm: "SAVE"
                },
                successfulAlert: {
                    title: "Successfull operation",
                    editDescription: "Your item was successfuly edited",
                    addDescription: "Your item was successfuly added"
                }
            },
            delete: {
                allItems: {
                    title: "Delete all items?",
                    description: "{quantity} items will be removed from the inventory. This process can not be undone."
                },
                singleItem: {
                    title: "Delete {name}?",
                    description: "{name}  will be removed from the inventory. This process can not be undone.",
                },
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DELETE"
                },
                successfulAlert: {
                    title: "Successfull operation",
                    description: "Removed from inventory"
                }
            },
            inventoryCompleted: {
                title: "Are you SURE you’re done listing your items?",
                description: "Your list will be marked as complete and you will not be able to modify it afterwards. If further changes are needed you will need to contact us to make the change for you.",
                disclaimer: "",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "ACCEPT"
                }
            },
            insuranceTerms: {
                linkText: {
                    text: `Some items are excluded from coverage, or have certain packing requirements.`,
                    summaryExclusions: 'Please, {exlcusions}',
                    link: "read the summary of exclusions"
                },
                title: "Insurance Coverage Terms and Conditions Summary",
                buttons: {
                    close: "CLOSE"
                }
            }
        },
        page404 : {
            title: "Nothing to see here!",
            goHome: "Go to the home page"
        },
        exceptionAlerts: {
            serverError: {
                title: "Oops! There has been an error",
                description: ""
            },
            tokenExpiredError: {
                title: "Oops! Auth has expired",
                description: ""
            }
        }
    }
};

